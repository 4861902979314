export const districts = [
  "Abim",
  "Adjumani",
  "Agago",
  "Alebtong",
  "Amolatar",
  "Amudat",
  "Amuria",
  "Amuru",
  "Apac",
  "Arua",
  "Budaka",
  "Bududa",
  "Bugiri",
  "Bugweri",
  "Buhweju",
  "Buikwe",
  "Bukedea",
  "Bukomansimbi",
  "Bukwa",
  "Bulambuli",
  "Buliisa",
  "Bundibugyo",
  "Bunyangabu",
  "Bushenyi",
  "Busia",
  "Butaleja",
  "Butambala",
  "Butebo",
  "Buvuma",
  "Buyende",
  "Dokolo",
  "Gomba",
  "Gulu",
  "Hoima",
  "Ibanda",
  "Iganga",
  "Isingiro",
  "Jinja",
  "Kaabong",
  "Kabale",
  "Kabarole",
  "Kaberamaido",
  "Kagadi",
  "Kakumiro",
  "Kalangala",
  "Kaliro",
  "Kalungu",
  "Kampala",
  "Kamuli",
  "Kamwenge",
  "Kanungu",
  "Kapchorwa",
  "Kapelebyong",
  "Karenga",
  "Kasanda",
  "Kasese",
  "Katakwi",
  "Kayunga",
  "Kibaale",
  "Kiboga",
  "Kibuku",
  "Kibuube",
  "Kiruhura",
  "Kiryandongo",
  "Kisoro",
  "Kitgum",
  "Koboko",
  "Kole",
  "Kotido",
  "Kumi",
  "Kwania",
  "Kween",
  "Kyankwanzi",
  "Kyegegwa",
  "Kyenjojo",
  "Kyotera",
  "Lamwo",
  "Lira",
  "Luuka",
  "Luweero",
  "Lwengo",
  "Lyantonde",
  "Manafwa",
  "Maracha",
  "Masaka",
  "Masindi",
  "Mayuge",
  "Mbale",
  "Mbarara",
  "Mitooma",
  "Mityana",
  "Moroto",
  "Moyo",
  "Mpigi",
  "Mubende",
  "Mukono",
  "Nabilatuk",
  "Nakapiripirit",
  "Nakaseke",
  "Nakasongola",
  "Namayingo",
  "Namisindwa",
  "Namutumba",
  "Napak",
  "Nebbi",
  "Ngora",
  "Ntoroko",
  "Ntungamo",
  "Nwoya",
  "Obongi",
  "Omoro",
  "Otuke",
  "Oyam",
  "Pader",
  "Pakwach",
  "Pallisa",
  "Rakai",
  "Rubanda",
  "Rubirizi",
  "Rukiga",
  "Rukungiri",
  "Sembabule",
  "Serere",
  "Sheema",
  "Sironko",
  "Soroti",
  "Tororo",
  "Wakiso",
  "Yumbe",
  "Zombo",
]
